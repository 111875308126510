import React from "react"
import { Row, Col, Card, Container, Button } from "react-bootstrap"
import Layout from "../components/Layaout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"





const IndexPage = () => {

  const { placeholderImage } = useStaticQuery(
    graphql`
        query {
          placeholderImage: file(relativePath: { eq: "bgImage-resitrans.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                transformOptions: {grayscale: false}
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        `
)
const pluginImage = getImage(placeholderImage);
  
  

  return (

  <Layout >
    <SEO 
    title="Residencias Transparentes" 
    description="Proyecto de información abierta para el sector geroasistencial en españa"
    charSet="utf-8"
    />
    

    <Container className="mt-4" >
      
    <BgImage className="jumbotron p-5  " image={pluginImage} >
      <h1 className="diplay-4 text-white" style={{textShadow: "-1px 1px 25px rgba(0, 0, 0, 0.80)"  }}>El futuro de las residencias empieza aquí</h1>
      <p className="lead text-white"style={{textShadow: "-1px 1px 25px rgb(0, 0, 0, 0.80)" }}>Estamos en el camino de construir algo innovador. Queremos entregar datos que aporten valor a los profesionales del sector geroasistencial.</p>
      <hr className="my-4 text-white"></hr>
      <p className=" text-white">Aquí podras descargar los informes y acceder a los datos del proyecto</p>
      <p className="lead">
    <Button  variant="custom" href="/app/registro" style={{color: "white", backgroundColor: "#df431dA2",
    borderColor: "#df431dA2", }}>Más información</Button>
      </p>
      </BgImage>
      
      
      <Row >
        <Col md={6} className="mt-4 mb-4">
          <h4>¿Qué es residencias transparentes?</h4>

          <p>Es un proyecto de información abierta del sector geroasistencial en España.
            Utilizamos datos de fuentes publicas y privadas que servirán en el diseño futuro de estrategias dentro del sector de las residencias de personas mayores.
            Es un proyecto promovido por inforesidencias.com y Analistas Financieros Internacionales</p>
          

          
        </Col>
        <Col md={6} className="mt-4 mb-4">
        <h4>¿A quíen puede interesar?</h4>
                      
          <p>Estamos en el camino de consturir algo innovador. Queremos entregar datos que aporten valor a los profesionales del sector de cuidado de personas mayores,
            así como consultores, investigadores, empresas y administraciones públicas interesados en usar estos datos para mejorar sus estrategias de cuidado hacia las personas mayores en España </p>

        </Col>
      </Row>
    </Container>
    <Container className="bg-light p-3" >
    <h3>Informes e índices</h3>
    <Row>
      
      <Col md={6}>
      <Card className="mb-2">
                              <Card.Body>
                                  
                                 <StaticImage src="../../images/portada-contexto.png" />
                                  
                              
                                  <Card.Title className="mt-3">La dependencia en España</Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted">
                                      Una mirada a través de los datos
                                  </Card.Subtitle>
                                  <Card.Text>
                                  Contexto del uso de datos e inteligencia de datos en el análisis de la dependenicia de las personas mayores. 
                                  </Card.Text> 
                                  <Button variant="outline-primary"  href="/app/registro">Más información</Button>
                                  
                              </Card.Body>
                          </Card>
      </Col>
      <Col md={6}>
      <Card className="mb-5" >
                             
                          <Card.Body>
                              
                              <StaticImage src="../../images/precios.png" className="p-3"/>
                              
                              
                           
                                  <Card.Title className="mt-3">Informes de precios</Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted">
                                      Residencias de España
                                  </Card.Subtitle>
                                  <Card.Text>
                                  IX Informe de Inforesidencias sobre precios  de residencias en España con información a nivel general, por provincias y comunidades autonomas.
                                  </Card.Text> 
                                  
                                      <Button variant="outline-primary"  href="/app/registro">Más información</Button>
                                      
                          </Card.Body>
          </Card>
      </Col>
    </Row>
      <Row>
        
        <Col md={6}>
        <Card className="mb-2">
                                <Card.Body>
                                    
                                   
                                    
                                
                                    <Card.Title className="mt-3">Índice de transparencia</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        Residencias en España
                                    </Card.Subtitle>
                                    <Card.Text>
                                    El Índice de Transparencia de inforesidencias (IT) se creó con el animo de promover la transparencia de información por parte de las residencias gariatricas en España. 
                                    </Card.Text> 
                                    <Button variant="outline-primary"  href="/app/registro">Más información</Button>
                                </Card.Body>
                            </Card>
        </Col>
        <Col md={6}>
        <Card className="mb-5" >
                               
                            <Card.Body>
                                
                                
                                
                                
                             
                                    <Card.Title className="mt-3">Índice IR</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        El "Big Mac idex" de las residencias 
                                    </Card.Subtitle>
                                    <Card.Text>
                                    Inspirado en famoso Big Mac Index del Economist, este índice mide el precio de las residencias de España en relación con el poder adquisitivo, comparandolo ademas por países. 
                                    </Card.Text> 
                                    
                                        <Button href="/ir" variant="outline-primary">Más información</Button>
                                       
                            </Card.Body>
                        </Card>
        

        </Col>
      </Row>
      
    </Container>

  </Layout>
)
  }

export default IndexPage
